import React, { useState } from 'react';
import './App.css';
import logo from "./logo.svg";

import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"

import locationsdb from "./swagger/locationsdb/0.0.1.json"
import broadcasterapi from "./swagger/broadcasterapi/0.0.1.json"
import statsapi from "./swagger/statsapi/4.0.0.json"

function App() {

  const [ api, setApi ] = useState<any>(locationsdb);

  return (
    <div className="container">
      <div className="row">
        <div className="app-nav col-3">
          
          <img src={logo} />

          <h1>Partner Portal</h1>
          
          <ul className="nav flex-column">
            
          <li className="nav-item">
            <span className="badge crooked bg-warning text-dark">v4.0.0</span>
              <a className="nav-link" onClick={() => setApi(statsapi)} href="#statsapi">
                Stats API
              </a>
              <p className="api-description">
                API for accessing GameSheet Stats.
              </p>
            </li>
            
            <li className="nav-item">
              <span className="badge crooked bg-secondary text-dark">v0.0.1</span>
              <a className="nav-link" onClick={() => setApi(locationsdb)} href="#locationdb">
                Location Library API
              </a>
              <p className="api-description">
                A multi-partner shared location library with partner data protection.
              </p>
            </li>
            
            <li className="nav-item">
              <span className="badge crooked bg-secondary text-dark">v0.0.1</span>
              <a className="nav-link" onClick={() => setApi(broadcasterapi)} href="#broadcasterapi">
                Broadcaster Integration API
              </a>
              <p className="api-description">
                API for extracting in-game data for broadcaster live score overlay.
              </p>
            </li>
            
            <li className="nav-item">
              <span className="badge crooked bg-primary">Coming Soon</span>
              <a className="nav-link" href="#scheduleapi">
                Schedule Integration API
              </a>
              <p className="api-description">
                API for syncronizing remote schedules with GameSheet.
              </p>
            </li>

          </ul>
        </div>
        <div className="app-stage col-9">
          <SwaggerUI spec={api} />
        </div>
      </div>
    </div>
  );
}

export default App;
